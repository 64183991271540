// src/apolloClient.js

import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

// Determine the API URL based on the environment
const api_url =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/graphql' // Development API URL
    : 'https://aethericai.onrender.com/graphql'; // Production API URL

// Create an HTTP link to your GraphQL server
const httpLink = createHttpLink({
  uri: api_url,
});

// Use setContext to add the Authorization header with the token from localStorage
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

// Create the Apollo Client instance
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
