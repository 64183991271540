// src/components/Home/home.js

import React from 'react';
import logo from '../../assets/images/AethericAI.png';

const Home = () => {
  return (
    <div className="flex justify-center items-center bg-black flex-grow">
      <img
        src={logo}
        alt="AethericAI Logo"
        // className="max-w-2/3 max-h-2/3"
      />
    </div>
  );
};

export default Home;
