// src/App.js

import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import AuthContext from './contexts/AuthContext';
import LoginModal from './components/LoginModal';
import Home from './components/Home/home';
import Dashboard from './components/Dashboard/dashboard';

function App() {
  const { user, logout } = useContext(AuthContext);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const handleLoginClick = () => {
    setShowLoginModal(true);
  };

  const handleCloseModal = () => {
    setShowLoginModal(false);
  };

  const handleLogoutClick = () => {
    logout();
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-black text-white">
        {/* Header */}
        <header className="relative h-12 bg-black">
          {user ? (
            <div className="absolute top-2 right-5 flex items-center space-x-4">
              <span className="text-base">Welcome, {user.username}!</span>
              <button
                onClick={handleLogoutClick}
                className="bg-black text-white px-3 py-1 border border-white rounded hover:bg-gray-800 transition"
              >
                Logout
              </button>
            </div>
          ) : (
            <button
              onClick={handleLoginClick}
              className="absolute top-2 right-5 bg-black text-white px-3 py-1 border border-white rounded hover:bg-gray-800 transition"
            >
              Login
            </button>
          )}
        </header>

        {/* Main Content */}
        <main className="flex-grow flex items-center justify-center">
          <Routes>
            <Route path="/" element={<Home />} />  {/* Home route */}
            <Route path="/dashboard" element={<ProtectedRoute component={Dashboard} />} />
            {/* Add more routes here as needed */}
          </Routes>
        </main>

        {/* Login Modal */}
        {showLoginModal && <LoginModal onClose={handleCloseModal} />}
      </div>
    </Router>
  );
}

// ProtectedRoute component to restrict access to authenticated users
const ProtectedRoute = ({ component: Component }) => {
  const { user } = useContext(AuthContext);
  return user ? <Component /> : <Navigate to="/" />;
};

export default App;
